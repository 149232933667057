<template>
  <div class="dynamic-font word-popover">
    <b-button
      v-if="metaId && canEditEbook"
      variant="flat-success"
      class="btn-icon edit-icon"
      @click="showEdit = true"
    >
      <feather-icon icon="EditIcon" />
    </b-button>
    <span
      class="text-transparent-theme"
      @click="($event) => (isWordPowerEnabled ? handleClick($event) : null)"
      v-html="getFormattedText"
    />

    <vocab-card-with-language
      :modal-key="popover"
      :vocab-data="vocabData"
      :active-words-list="activeWordsList"
      :is-loading="isLoading"
      :language="activeLanguage"
      @set-active-language="(lang) => (activeLanguage = lang)"
      @close="removeWord"
    />
    <edit-meta
      v-if="showEdit"
      :meta-id="metaId"
      :content="rawText"
      @close="showEdit = false"
    />
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
// import { checkSettings } from '@/utils/visibilitySettings'
import { checkSettings } from '@/utils/visibilitySettings'
import { getUserData } from '@/auth/utils'
import { USER_TYPE_SUPER, USER_TYPE_TEACHER } from '@/const/userType'
import VocabCardWithLanguage from '../../curriculum/components/layouts/components/VocabCardWithLanguage.vue'
import EditMeta from './EditMeta.vue'

export default {
  components: {
    VocabCardWithLanguage,
    EditMeta,
    BButton,
  },
  props: {
    metaId: {
      type: Number,
      default: 0,
    },
    text: {
      type: String,
      default: () => '',
    },
    rawText: {
      type: String,
      default: () => '',
    },
    popover: {
      type: String,
      default: () => '',
    },
    wordPowerEnabled: {
      type: Boolean,
      default: () => null,
    },
  },
  data() {
    return {
      vocabData: {},
      activeWord: '',
      activeLanguage: null,
      isLoading: false,
      activeWordsList: [],
      self: getUserData(),
      showEdit: false,
    }
  },
  computed: {
    fontStyle() {
      // !! the feature is set inactive later we will add fixed fonts
      /*  if (this.$store.state.studentLab?.activeViewOfLab === 'feedback') {
        return FontLogic(this.getFormattedText)
      } */
      return null
    },
    splittedText() {
      const words = []
      this.text.split(' ').forEach(word => {
        const tag = this.getHtmlTag(word)
        if (tag) {
          word.split(tag).forEach(term => {
            if (term && term !== '\n') words.push(term)
            else words.push(tag)
          })
        } else words.push(word)
      })
      return words
    },
    getFormattedText() {
      let html = ''
      const tagsAndText = this.text.split(/(<[^>]+>)/).filter(Boolean)
      let ignoreWordPower = false
      // TODO: Find the better way
      tagsAndText.forEach(word => {
        const tag = this.getHtmlTag(word)
        if (tag) {
          html += tag
          ignoreWordPower = tag.includes('youtube-video-button')
          console.log(tag, ignoreWordPower)
        } else if (ignoreWordPower) {
          html += word
        } else {
          word.split(' ').forEach((term, index) => {
            if (
              term.replace(/[^\w\s]/g, '').length > 2
              && Number.isNaN(Number(term))
            ) {
              html += `<span data-word="${term}-${index}" class="cursor-pointer clickable-word"> ${term}</span>`
            } else html += ` ${term}`
          })
        }
      })
      return html
    },
    isASuper() {
      return this.self.usertype === USER_TYPE_SUPER
    },
    isATeacher() {
      return this.self.usertype === USER_TYPE_TEACHER
    },
    canEditEbook() {
      return this.isASuper || this.isATeacher
    },
    isWordPowerEnabled() {
      if (this.isASuper) {
        return true
      }
      if (this.wordPowerEnabled !== null) return this.wordPowerEnabled

      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta

      return Number(
        checkSettings('word_power', schoolSetting, distSetting)?.value ?? 0,
      )
    },
  },
  methods: {
    handleClick(e) {
      if (e.target.matches('.clickable-word', 'SPAN')) {
        const term = e.target.textContent
        this.activeWord = term.replace(/[^\w\s]/g, '')
        const clickRect = e.target.getBoundingClientRect()
        if (this.activeWordsList.indexOf(this.activeWord) === -1) {
          this.getImageLibrary(clickRect)
        }
      }
    },
    getImageLibrary(clickRect) {
      this.isLoading = true
      useJwt
        .getImageLibrary({
          params: {
            word: this.activeWord,
            exact: true,
          },
        })
        .then(response => {
          const { data } = response.data

          const vocabData = data.find(vocab => vocab.lang === 'english') ?? {}
          this.vocabData[this.activeWord.toLowerCase()] = {
            ...vocabData,
            options: { left: clickRect.x, top: clickRect.top },
          }
          this.$nextTick(() => {
            this.activeWordsList.push(this.activeWord)
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getHtmlTag(word) {
      return word.match(/<[^>]*>/g)?.[0]
    },
    removeWord(val) {
      const index = this.activeWordsList.indexOf(val)
      this.activeWordsList.splice(index, 1)
    },
  },
}
</script>
<style lang="scss">
@import url("../font/font.scss");
.word-popover {
  .edit-icon {
    display: none;
    position: absolute;
    // margin-top: -10px;
    right: 0,
  }
}
.word-popover:hover {
  .edit-icon {
    display: inline;
  }
}
</style>
