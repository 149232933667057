<template>
  <b-row
    class="m-0 drag-position"
    :class="{'fill-row': !copyPasteEnabled}"
  >
    <b-col
      class="problem-card-title text-center"
      md="12"
    >
      <h4 class="mb-0">
        {{ getProblemHintsDescription($t("student-test-module.drag-position-problem-hint")) }}
      </h4>
    </b-col>

    <b-col
      md="12"
      class="problem-overview-content"
    >
      <div
        v-if="defaultAnswers.length > 1 && shouldShowHints.hints"
        class="text-center"
      >
        <div
          v-if="feedbackQuestionComputed.length > 0"
          class="d-flex justify-content-center"
        >
          <div
            v-for="ans,index in feedbackQuestionComputed"
            :key="index"
            no-body
            class="mr-2"
          >
            <b-img
              v-if="shouldShowHints.image_hints"
              :src="ans.image_url || ans.image"
              thumbnail
              fluid
              style="height: 50px; width: 50px;"
            />
            <div
              v-if="shouldShowHints.text_hints"
              class="small text-bold"
              v-html="ans.title"
            />
          </div>
        </div>
        <template v-else-if="shouldShowHints.text_hints">
          <span
            v-for="ans,index in defaultAnswers"
            :key="index"
            class="mr-2"
            v-html="ans"
          />
        </template>
      </div>
      <div v-if="bodyAudio">
        <audio-player :src="bodyAudio" />
      </div>
      <validation-observer ref="submitQuestionForm">
        <div class="ck-content"
             :class="{
               'pointer-disabled': !!feedback?.test_id
             }"
        >
          <!-- eslint-disable vue/no-v-html -->
          <span
            v-if="fullHtml"
            v-html="fullHtml"
          />
          <!-- eslint-enable vue/no-v-html -->
          <!-- <span v-html="fullHtml" /> -->
          <div
            v-else
            class="ck-content"
            :class="selectedItemForTouch !== null ? 'is-being-touched' : ''"
          >
            <!-- eslint-disable vue/no-v-html -->
            <div
              v-if="template && template.before_template && template.before_template.length>0 &&
                (getTemplateComponent !== 'Shapes' || getTemplateComponent === 'Shapes' && template.template[0].type !== 'fib') && getTemplateComponent !== 'side-cards' "
              v-html="beforeHtml"
            />
            <!-- eslint-enable vue/no-v-html -->
            <component
              :is="getTemplateComponent"
              v-if="template && template.template"
              :place-holder="placeHolder"
              :data="template.template"
              :is-a-problem="true"
              :question-formatter="formatQuestion"
              :template-options="getTemplateOptions"
            >
              <div
                v-if="(getTemplateComponent === 'Shapes' && template.template[0].type === 'fib') || getTemplateComponent == 'side-cards' "
                v-html="beforeHtml"
              />

            </component>
            <!-- <DropArea v-if="getTemplateComponent == 'side-cards'"
                      ref="answerListArea"
                      :undo-answer="undoAnswer"
                      :start-touch="startTouch"
                      :handle-double-click="handleDoubleClick"
                      :start-drag="startDrag"
                      :get-random-variant="getRandomVariant"
                      :unescape="unescape"
                      :style="{
                        visibility: isValidated ? 'hidden' : 'visible'
                      }"
                      :is-preview-mode="isPreviewMode"
                      :default-answers="defaultAnswers"
                      class="mt-5"
            /> -->
            <!-- eslint-disable vue/no-v-html -->
            <div
              v-if="
                template &&
                  template.after_template &&
                  template.after_template.length > 0
              "
              v-text-to-speech
              v-html="afterHtml"
            />
            <!-- eslint-enable vue/no-v-html -->
          </div>
          <span
            v-for="(question, index) in localQuestions"
            v-show="false"
            :key="'question_' + index"
          >
            <validation-provider
              v-if="question == placeHolder"
              v-slot="{ errors }"
              :name="'Field_' + (index + 1)"
              rules="required"
            >
              <b-form-input
                :id="'Field_' + (index + 1)"
                v-model="answers[index]"
                :disabled="mode === 'feedback'"
                style="display: inline-block; width: 150px; margin-top: 2px"
                class="mx-1 right-answer"
                :state="errors.length > 0 ? false : null"
                size="sm"
                :name="'Field_' + (index + 1)"
                onpaste="return ${copyPasteEnabled};"
                @input="checkValidation"
              />
            </validation-provider>
            <!-- eslint-disable vue/no-v-html -->
            <span
              v-else
              v-html="question"
            />
            <!-- eslint-enable vue/no-v-html -->
          </span>
        </div>
      </validation-observer>
      <DropArea
        v-if="!hasFeedback"
        ref="answerListArea"
        :undo-answer="undoAnswer"
        :style="{
          visibility: isValidated ? 'hidden' : 'visible'
        }"
        :start-touch="startTouch"
        :handle-double-click="handleDoubleClick"
        :start-drag="startDrag"
        :get-random-variant="getRandomVariant"
        :unescape="unescape"
        :is-preview-mode="isPreviewMode"
        :default-answers="defaultAnswers"
      />
    </b-col>
    <portal
      to="problemFeedBack"
    >
      <b-row class="m-0">
        <b-col md="12"
               class="p-0"
        >
          <feedback
            :feedback="feedback"
            :problem-statement-id="problemStatementId"
          />
        </b-col>
        <b-col
          v-if="!isPreview"
          class="m-0 p-0"
          md="12"
        >
          <actions
            :has-feedback="hasFeedback"
            :is-processing="isProcessing"
            :level="level ? level : ''"
            :on-submit="submit"
            :disabled="!isValidated"
            :has-minimal-view="hasMinimalView"
            :tooltip-text="
              $t('student-test-module.fill-problem-validation-message')
            "
            :display-result="displayResult"
            :button-title="hasFeedback ? $t('next') : $t('submit')"
            :engine="engine"
            :school-settings="schoolSettings"
            :district-settings="districtSettings"
            v-on="$listeners"
          />
        </b-col>
      </b-row>
    </portal>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormInput, BImg, BCard, BButton,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import { mapGetters } from 'vuex'
import { checkSettings } from '@/utils/visibilitySettings'
import Paragraph from '@/views/common/templates/components/Paragraph.vue'
import ImageQA from '@/views/common/templates/components/ImageQA.vue'
import Shapes from '@/views/common/templates/components/Shapes.vue'
import ImageParagraph from '@/views/common/templates/components/ImageParagraph.vue'
import CenteredCard from '@/views/common/templates/components/CenteredCard.vue'
import Cards from '@/views/common/templates/components/Cards.vue'
import SideCards from '@/views/common/templates/components/SideCard.vue'
import AudioPlayer from '@/views/common/components/AudioPlayer.vue'
import DropArea from './drop-area.vue'
import Actions from '../Actions.vue'
import Feedback from '../Feedback.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    Feedback,
    Actions,
    Paragraph,
    ImageParagraph,
    ImageQA,
    CenteredCard,
    SideCards,
    Cards,
    Shapes,
    BImg,
    BCard,
    BButton,
    AudioPlayer,
    DropArea,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    questions: {
      type: Array,
      required: true,
    },
    previewAnswers: {
      type: Array,
      default: () => [],
    },
    isProcessing: {
      type: Boolean,
      required: true,
    },
    feedback: {
      type: Object,
      default: () => { },
    },
    displayResult: {
      type: Boolean,
      default: false,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      required: true,
    },
    level: {
      type: Number,
      default: 1,
    },
    feedbackQuestion: {
      type: Array,
      default: () => [],
    },
    problemStatementId: {
      type: [Number, String],
      required: false,
    },
    rules: {
      type: Object,
      default: () => null,
    },
    startTime: {
      type: [String, Date],
      required: false,
      default: () => null,
    },
    engine: {
      type: String,
      default: () => '',
    },
    hasMinimalView: {
      type: Boolean,
      default: false,
    },
    schoolSettings: {
      type: Array,
      default: () => [],
    },
    districtSettings: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      placeHolder: '[ ]',
      localQuestions: [],
      isValidated: false,
      answers: {},
      fullHtml: '',
      template: null,
      beforeHtml: '',
      afterHtml: '',
      centralHtml: '',
      defaultShape: 'hexagon.png',
      fibType: 'fib',
      selectedItemForTouch: null,
    }
  },
  computed: {
    ...mapGetters('problem', {
      validation: 'getValidations',
      shapes: 'getShapes',
    }),
    bodyAudio() {
      return this.questions[0]?.extra_file
    },
    feedbackQuestionComputed() {
      if (this.feedback?.template?.template) {
        return this.feedback?.template?.template
      }
      return this.feedbackQuestion
    },
    isPreviewMode() {
      return this.previewAnswers && this.previewAnswers.length
    },
    getTemplateComponent() {
      if (this.template?.type === 'paragraph') return 'Paragraph'
      if (this.template?.type === 'image-paragraph') return 'ImageParagraph'
      if (this.template?.type === 'image-qa') return 'ImageQA'
      if (this.template?.type === 'shapes') return 'Shapes'
      if (this.template?.type === 'centered-cards') return 'CenteredCard'
      if (this.template?.type === 'cards') return 'Cards'
      if (this.template?.type === 'side-cards') return 'side-cards'
      return ''
    },
    shouldShowHints() {
      // eslint-disable-next-line eqeqeq
      const image = this.rules?.hide_image_hints != '1'
      // eslint-disable-next-line eqeqeq
      const text = this.rules?.hide_text_hints != '1'
      const isHiddenByDistrict = this.$store.getters['studentLab/isProblemHintsHiddenByDistrict']
      return {
        // eslint-disable-next-line eqeqeq
        hints: !isHiddenByDistrict && this.rules?.hide_hints != '1' && (image || text),
        image_hints: image,
        text_hints: text,
      }
    },
    hasFeedback() {
      return !!(this.feedback?.text?.length > 0 || this.feedback?.image || this.feedback?.audio || this.feedback?.template)
    },
    getShape() {
      if (this.template?.type !== 'shapes') return ''
      const sh = this.template?.template[0]?.shape ? this.template?.template[0]?.shape : this.defaultShape
      return this.shapes.filter(shape => shape.value === sh)[0]
    },
    getFibType() {
      if (this.template?.type !== 'shapes') return ''
      return this.template?.template[0]?.type ? this.template?.template[0]?.type : this.fibType
    },
    getBgColor() {
      if (this.template?.type !== 'shapes') return ''
      return this.template?.template[0]?.bgColor
    },
    getTemplateOptions() {
      if (this.template?.type !== 'shapes') return ''
      const sh = this.template?.template[0]?.shape ? this.template?.template[0]?.shape : this.defaultShape

      return {
        shape: this.shapes.filter(shape => shape.value === sh)[0],
        type: this.template?.template[0]?.type ? this.template?.template[0]?.type : this.fibType,
        bgColor: this.template?.template[0]?.bgColor,
        textAlign: this.template?.template[0]?.textAlign ? this.template?.template[0]?.textAlign : 'left',
      }
    },
    realAnswerPattern() {
      let answers = []
        // eslint-disable-next-line no-unused-expressions
        this.questions?.forEach(question => {
          answers = [...answers, ...question.answers.map(ans => ans.text)]
        })
        return answers
    },
    defaultAnswers() {
      let answers = []
          // eslint-disable-next-line no-unused-expressions
          this.questions?.forEach(question => {
            answers = [...answers, ...question.answers.map(ans => ans.text)]
          })
          // eslint-disable-next-line eqeqeq
          if (this.rules?.disable_random_hints != '1') {
            answers.sort(() => Math.random() - 0.5)
          }
          return answers
    },
    copyPasteEnabled() {
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      const setting = checkSettings('copy_paste', schoolSetting, distSetting)
      console.log('here', setting)
      if (setting.value === '1') {
        return true
      }
      return false
    },
  },

  watch: {
    question() {
      this.initData()
    },
    validation() {
      this.setValidationClasses()
    },
  },

  mounted() {
    this.initData().then(() => {
      this.changeInputSize()
      if (this.previewAnswers && this.previewAnswers.length) {
        this.setValidationClasses(true)
      }
    })
    window.HANDLE_DRAG_DROPPED = event => {
      this.onDrop(event)
    }
    window.ALLOW_DROP = event => {
      event.preventDefault()
    }
  },

  methods: {
    unescape(string) {
      // create an element with that html
      const e = document.createElement('textarea')

      // get the html from the created element
      e.innerHTML = string

      return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue
    },
    handleDoubleClick(index) {
      this.selectedItemForTouch = index
      const spans = document.querySelectorAll('.answer-input-area') // Get all span elements
      let firstSpanWithoutButton = null

      // eslint-disable-next-line no-restricted-syntax
      for (const span of spans) {
        if (!span.querySelector('button')) {
          firstSpanWithoutButton = span
          break
        }
      }
      if (firstSpanWithoutButton) {
        this.onDrop({ target: firstSpanWithoutButton })
      }
    },
    sortAnswerByKey(ansObj) {
      // eslint-disable-next-line radix
      const extractNumber = key => parseInt(key.split('-')[1])

      // Sorting the object keys based on the extracted number
      const sortedKeys = Object.keys(ansObj).sort((a, b) => extractNumber(a) - extractNumber(b))

      // Creating a new object with the sorted keys
      const sortedAnswers = []
      sortedKeys.forEach(key => {
        sortedAnswers.push(key)
      })
      return sortedAnswers
    },
    afterDrop() {
      // reform answers
      const inputArea = document.querySelectorAll('.answer-input-area')
      const ansObj = {}
      inputArea.forEach(item => {
        const ansButton = item.getElementsByTagName('button')[0]
        const inputAttribute = item.getElementsByTagName('input')[0].getAttribute('data-id')
        ansObj[inputAttribute] = ansButton?.innerText || ''
      })
      this.answers = ansObj
      this.selectedItemForTouch = null
      this.checkValidation()
    },
    undoAnswer(evt, directIndex = null) {
      if (directIndex !== null) {
        const id = `draggable_answer_${directIndex}`
        if (!this.$refs.answerListArea.$refs.area.querySelector(`#${id}`)) {
          const elToAdd = document.getElementById(id)
          this.$refs.answerListArea.$refs.area.appendChild(elToAdd)
        }
        return
      }
      if (evt.target.classList.contains('answer-list-area')) {
        const index = this.selectedItemForTouch !== null ? this.selectedItemForTouch : evt.dataTransfer?.getData('itemID')
        const elToAdd = document.getElementById(`draggable_answer_${index}`)
        evt.target.appendChild(elToAdd)
        this.afterDrop()
      }
    },
    getRandomVariant() {
      const array = ['outline-primary']
      return array[Math.floor(Math.random() * array.length)]
    },
    startTouch(index) {
      this.selectedItemForTouch = index
    },
    startDrag(evt, index) {
      // eslint-disable-next-line no-param-reassign
      evt.dataTransfer.dropEffect = 'move'
      // eslint-disable-next-line no-param-reassign
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData('itemID', index)
    },
    // eslint-disable-next-line no-unused-vars
    onDrop(evt, nextAdd = true) {
      const event = evt.target.classList.contains('answer-input') ? evt.target : evt.target.parentElement?.parentElement

      if (event.querySelector('.draggable-answer')) {
        const draggableAnswer = event.querySelector('.draggable-answer')
        // eslint-disable-next-line radix
        const id = parseInt(draggableAnswer.getAttribute('id').match(/\d+$/)[0])
        this.undoAnswer(null, id)
      }
      if (event.classList.contains('answer-input')) {
        const index = this.selectedItemForTouch !== null ? this.selectedItemForTouch : evt.dataTransfer?.getData('itemID')
        const elToAdd = document.getElementById(`draggable_answer_${index}`)
        const currentAnswer = event.getElementsByTagName('button')[0]
        if (nextAdd && currentAnswer && elToAdd.parentElement) {
          this.selectedItemForTouch = currentAnswer.getAttribute('data-pos')
          this.onDrop({ target: elToAdd.parentElement }, false)
        }
        event.appendChild(elToAdd)
        this.afterDrop()
      }
    },
    initData() {
      return new Promise(resolve => {
        try {
          this.template = JSON.parse(this.questions[0].text)
          if (
                this.template?.template?.[0]?.type === 'qa'
                || this.template?.type === 'image-qa'
                || this.template?.type === 'image-paragraph'
          ) {
            this.formatQA(this.template?.template, 'subtitle')
          }

          if (this.template?.before_template) {
            this.beforeHtml += this.formatQuestion(this.template?.before_template, 'before')
          }

          if (this.template?.after_template) {
            this.afterHtml += this.formatQuestion(this.template?.after_template, 'after')
          }
        } catch {
          this.answers = {}
          let question = this.questions[0].text
          question = question.replaceAll(this.placeHolder, `$${this.placeHolder}$`)
          this.localQuestions = question.split('$').filter(e => e.length > 0)
          this.localQuestions.forEach((element, index) => {
            if (element === this.placeHolder) {
              // this.answers[index] = ''
              this.fullHtml += `
                      <input
                        type="text"
                        id="'Field_' + ${(index + 1)}"
                        data-id="${index}"
                        readonly
                        style="display: inline-block; width: 4%;margin-top:2px"
                        class="mx-1 answer-input answer form-control"
                        :name="'Field_' + ${(index + 1)}"
                        onpaste="return false;"
                      />`
            } else {
              this.fullHtml += element
            }
          })
        } finally {
          resolve()
        }
      })
    },
    formatQuestion(question, dataString) {
      let html = ''
      question
        .replaceAll(this.placeHolder, `##${this.placeHolder}##`)
        .split('##')
        .filter(e => e.length > 0)
        .forEach((element, index) => {
          if (element === this.placeHolder) {
            // [`${dataString}-${index}`] = ''
            html += `
              <span style="" 
                class="answer-input answer-input-area answer text-center drop-to-me" 
                ondrop="HANDLE_DRAG_DROPPED(event)" 
                ondragover="ALLOW_DROP(event)"
                source-id="source-div-${index}"
                ontouchstart="HANDLE_DRAG_DROPPED(event)"
              >
                  <input
                    type="hidden"
                    id="'Field_' + ${(index + 1)}"
                    data-id="${dataString}-${index}"
                    onpaste="return false;"
                    :name="'Field_' + ${(index + 1)}"
                  />
                </span>`
          } else {
            html += element
          }
        })
      return html
    },
    formatQA(question) {
      for (let index = 0; index < question.length; index += 1) {
        // this.answers[`${dataString}-${index}-0`] = ''
      }
    },
    checkValidation() {
      this.isValidated = this.validate()
    },
    validate() {
      const keys = Object.keys(this.answers)
      for (let i = 0; i < keys.length; i += 1) {
        if (this.answers[keys[i]] === '' && keys[i] !== 'subtitle-0-0') return false
      }
      return true
    },
    submit() {
      this.$refs.submitQuestionForm.validate().then(success => {
        if (success) {
          const answers = []
          this.sortAnswerByKey(this.answers).forEach(key => {
            answers.push(this.answers[key])
          })
          this.$emit('submitQuestion', { lang_level: this.questions[0].lang_level, answer: answers })
        }
      })
    },
    setValidationClasses(insertInput = false) {
      const answers = [...document.querySelectorAll('.answer')]
      answers.forEach((answer, index) => {
        let isCorrect
        if (this.validation.length) isCorrect = this.validation[index]?.is_correct
        else isCorrect = this.previewAnswers[index]?.is_correct
        answer.classList.add(`answer-drag-${isCorrect}`)
        if (insertInput) {
          answer.insertAdjacentHTML('afterbegin', `<button type="button" class="btn btn-outline-primary no-transparent">${this.previewAnswers[index]?.input}</button>`)
        }
      })
    },
    clearValidationClasses() {
      const answers = document.querySelectorAll('.answer')
      answers.forEach(answer => {
        if (answer.classList.includes('answer-drag-0')) answer.classList.remove('answer-drag-0')
        if (answer.classList.includes('answer-drag-1')) answer.classList.remove('answer-drag-1')
      })
    },
    changeInputSize() {
      const questions = document.querySelectorAll('.answer-input')
      const [{ answers }] = this.questions
      const selectedAnswers = [...answers]
      selectedAnswers.sort((a, b) => a.id - b.id)
      questions.forEach((question, index) => {
        const maxSize = selectedAnswers[index]?.text?.length
        /* eslint-disable no-param-reassign */
        question.style.minWidth = maxSize < 5 ? `${maxSize + 2.2}vw` : `${maxSize / 1.2 + 1.2}vw`
        question.style.width = 'auto'
      })
    },
  },
}
</script>

<style lang="scss">
    .inline-p {
      display: inline-block;
    }
    .answer-drag-1 button {
      color: #2eb82e !important;
    }
    .answer-drag-0  button {
      color: #df0000 !important;
    }
    .fill-row {
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    .random-box {
      border: 1px solid magenta;
      width: 25vw;
      height: 25vh;
      margin: 0;
      position: absolute;

    }
    .is-being-touched .answer-input {
      border-color: yellow !important;
    }
    .pointer-disabled {
      pointer-events: none !important;
    }
    .underline-below::before {
        content: "";
        display: block;
        border-bottom: 1px solid black;
        margin-bottom: -1px; /* Adjust if necessary to match text size */
    }
    .answer-input-area .selectable-text {
      padding: 0 !important;
      margin-top: -3px !important;
    }
    .drop-to-me:not(:has(.draggable-answer)) {
      display: inline-table;
      width: 150px;
      margin-top:2px;
      border-bottom: dashed;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem
    }

</style>
